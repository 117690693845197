import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css';
import 'ant-design-vue/lib/statistic/style/index.css'
import {Statistic} from 'ant-design-vue'
import {
  Carousel,
  CarouselItem,
  Dialog,
  Row,
  Col,
  Button,
  Icon,
  Input,
  Select,
  Option,
  Table,
  TableColumn,
  Message,
  Loading,
  DatePicker,
  TimeSelect,
  TimePicker,
  Empty,
  Image,
  InputNumber,
  Collapse,
  CollapseItem,
  Radio, RadioGroup,
  MessageBox,
  Checkbox,
  Form,
  FormItem,
  Pagination
} from 'element-ui';

Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Dialog)
Vue.use(Row)
Vue.use(Col)
Vue.use(Button)
Vue.use(Icon)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(TimeSelect)
Vue.use(Empty)
Vue.use(Image)
Vue.use(InputNumber)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Checkbox)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Pagination)
Vue.use(Loading.directive)
Vue.use(Statistic)


Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$loading = Loading.service
