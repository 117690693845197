import Vue from 'vue'
import VueRouter from 'vue-router'

const home = () => import('../views/HomeView.vue')
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta:{title:'_HOME'},
    component: home
  },
  {
    path: '/index',
    name: 'index',
    meta:{title:'_Team'},
    component: () => import(/* webpackChunkName: "about" */ '../views/index')
  },
  {
    path: '/food',
    name: 'food',
    meta:{title:'_Menu'},
    component: () => import(/* webpackChunkName: "about" */ '../views/food')
  },
  {
    path: '/space',
    name: 'space',
    meta:{title:'_Space'},
    component: () => import(/* webpackChunkName: "about" */ '../views/space')
  },
  {
    path: '/booking',
    name: 'booking',
    meta:{title:'_Booking'},
    component: () => import(/* webpackChunkName: "about" */ '../views/booking')
  },
  {
    path: '/news',
    name: 'news',
    meta:{title:'_News'},
    component: () => import(/* webpackChunkName: "about" */ '../views/news')
  },
  
  {
    path: '/details',
    name: 'details',
    meta:{title:'_News'},
    component: () => import(/* webpackChunkName: "about" */ '../views/details')
  },
  {
    path: '/order',
    name: 'order',
    meta:{title:'居家雲隠'},
    component: () => import(/* webpackChunkName: "about" */ '../views/sendHome')
  },
  {
    path: '/myorder',
    name: 'myorder',
    meta:{title:'居家雲隠'},
    component: () => import(/* webpackChunkName: "about" */ '../views/myOrder')
  },
  {
    path: '/userInfo',
    name: 'userInfo',
    meta:{title:'居家雲隠'},
    component: () => import(/* webpackChunkName: "about" */ '../views/basicInfo')
  },
  {
    path: '/address',
    name: 'address',
    meta:{title:'居家雲隠'},
    component: () => import(/* webpackChunkName: "about" */ '../views/address')
  },
  {
    path: '/password',
    name: 'password',
    meta:{title:'居家雲隠'},
    component: () => import(/* webpackChunkName: "about" */ '../views/modifyPassword')
  },
  {
    path: '/orderDetails',
    name: 'orderDetails',
    meta:{title:'居家雲隠'},
    component: () => import(/* webpackChunkName: "about" */ '../views/myOrderDetails')
  }
]
const router = new VueRouter({
  mode: 'hash',//hash
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to,from, next) => {
  if(to.meta.title){
    document.title = to.meta.title
  }
  next()
})
export default router
