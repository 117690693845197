import Vue from 'vue'
import Vuex from 'vuex'
 Vue.use(Vuex)

const store = new Vuex.Store({
 state:{
  lang: sessionStorage.getItem('langType') || 'hk',
  name: localStorage.getItem('userName') || undefined,
  call: localStorage.getItem('userCall') || undefined,
   login:false
 },
 mutations:{
   changeLangType(state,val){
     sessionStorage.setItem('langType',val)
    state.lang = val
   },
   changeUserInfo(state,userInfo){
     state.name = userInfo.name
     state.call = userInfo.call

   },
   openLogin(state,val){
     //弹出登录框
     state.login = val
   }
 }
})

export default store